var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":("Edit " + (_vm.form.template_name))}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('validation-observer',{ref:"EditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.edit($event)}}},[_c('b-form-group',{attrs:{"label":"Template Name","label-for":"Template Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Template Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"template_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"template_name","state":errors.length > 0 ? false : null,"name":"template_name"},model:{value:(_vm.form.template_name),callback:function ($$v) {_vm.$set(_vm.form, "template_name", $$v)},expression:"form.template_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Upload File","label-for":"File"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Upload File")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"file","rules":"ext:doc,docx"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"file",attrs:{"state":Boolean(_vm.doc_file),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload()}},model:{value:(_vm.doc_file),callback:function ($$v) {_vm.doc_file=$$v},expression:"doc_file"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "+_vm._s(_vm.doc_file ? _vm.doc_file.name : "")+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"checkbox-10","name":"checkbox-10","checked":_vm.publishCustomer},model:{value:(_vm.publishCustomer),callback:function ($$v) {_vm.publishCustomer=$$v},expression:"publishCustomer"}},[_vm._v(" Publish to Customer ")])],1),_c('div',{staticClass:"mt-3"},[_c('h4',[_vm._v("Add Variables")]),_c('p',{staticClass:"mt-1"},[_vm._v(" The variables will be replaced with the actual values you provide while generating the report. Make sure the variables you add here exist in the template you upload. For ex. If you add `report_name` here, your template should contain `"+_vm._s(_vm.report_name)+"` ")]),_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Variable to be replaced","label-for":"variable"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Variable to be replaced")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"variable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("variable_" + (item.id)),"type":"text","placeholder":"Ex. report_title"},model:{value:(item.variable),callback:function ($$v) {_vm.$set(item, "variable", $$v)},expression:"item.variable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"A label for the variable","label-for":"Variable Label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("A label for the variable")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"variable_label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"variable_label","type":"text","placeholder":"Ex. Report Title"},model:{value:(item.variable_label),callback:function ($$v) {_vm.$set(item, "variable_label", $$v)},expression:"item.variable_label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),_c('b-button',{attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.repeateAgain}},[_vm._v(" Add New Variable ")])],2),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[_vm._v(" Save Template ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }